<template>
  <div class="col-12 bg-light">
    <div>
      <!-- <p class="text-left">Compila uno o più campi</p> -->
      <b-row cols="2" cols-md="3" cols-lg="4" class="justify-items-start">
        <!-- <div class="col-12 d-flex flex-wrap"> -->
        <b-form-group
          label="Regione"
          label-for="regione"
          class="text-left mx-auto col mb-1"
        >
          <b-form-select
            class="my-1 shadow_6"
            :options="regioni"
            name="regione"
            v-model="selected.regione"
            @change="getProvince(selected)"
            required
          >
            <b-form-select-option :value="null"
              >Seleziona Regione</b-form-select-option
            >
          </b-form-select>
        </b-form-group>
        <b-form-group
          label="Provincia"
          label-for="provincia"
          class="text-left mx-auto col mb-1"
        >
          <b-form-select
            class="my-1 shadow_6"
            :options="province"
            name="provincia"
            v-model="selected.provincia"
            @change="getComuni(selected)"
            required
          >
            <b-form-select-option :value="null"
              >Seleziona Provincia</b-form-select-option
            >
          </b-form-select>
        </b-form-group>
        <b-form-group
          label="Comune"
          label-for="comune"
          class="text-left mx-auto col mb-1"
        >
          <b-form-select
            class="my-1 shadow_6"
            name="comune"
            v-model="selected.comune"
            required
          >
            <b-form-select-option
              v-for="(com, ind) in comuni"
              :key="ind"
              :value="com"
              @change="setData"
              >{{ com.comune }}</b-form-select-option
            >
            <b-form-select-option :value="null"
              >Seleziona Comune</b-form-select-option
            >
          </b-form-select>
        </b-form-group>
        <!-- </div> -->

        <!-- <div class="col-12 d-flex flex-wrap"> -->
        <b-form-group
          label="Ragione sociale"
          label-for="denominazione"
          class="text-left mx-auto col mb-1"
        >
          <b-form-input
            class="my-1 shadow_6"
            name="denominazione"
            type="text"
            placeholder="ragione sociale..."
            v-model="filters.denominazione"
            minlength="5"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Cognome Titolare"
          label-for="surname"
          class="text-left mx-auto col mb-1"
        >
          <b-form-input
            class="my-1 shadow_6"
            name="surname"
            type="text"
            placeholder="cognome..."
            v-model="filters.surname"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Farmacia Comunale"
          label-for="comunale"
          class="text-left mx-auto col mb-1"
        >
          <b-form-select
            class="my-1 shadow_6"
            name="comunale"
            v-model="filters.comunale"
          >
            <b-form-select-option :value="true">sì</b-form-select-option>
            <b-form-select-option :value="false">no</b-form-select-option>
            <b-form-select-option :value="null">scegli</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <!-- </div>
        <div class="col-12 d-flex flex-wrap"> -->
        <b-form-group
          label="Partita Iva"
          label-for="partita_iva"
          class="text-left mx-auto col mb-1"
        >
          <b-form-input
            class="my-1 shadow_6"
            name="partita_iva"
            type="text"
            placeholder="partita iva..."
            v-model="filters.partita_iva"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Codice Fiscale"
          label-for="codice_fiscale"
          class="text-left mx-auto col mb-1"
        >
          <b-form-input
            class="my-1 shadow_6"
            name="codice_fiscale"
            type="text"
            placeholder="codice fiscale..."
            v-model="filters.codice_fiscale"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Codice Tracciabilità"
          label-for="codice_tracciabilita"
          class="text-left mx-auto col mb-1"
        >
          <b-form-input
            class="my-1 shadow_6"
            name="codice_tracciabilita"
            type="text"
            placeholder="codice tracciabilità..."
            v-model="filters.codice_tracciabilita"
          ></b-form-input>
        </b-form-group>
        <!-- </div>

        <div class="col-12 d-flex flex-wrap"> -->
        <b-form-group
          label="Pre-adesione FDS"
          label-for="preadesionefds"
          class="text-left mx-auto col mb-1"
        >
          <b-form-select
            class="my-1 shadow_6"
            name="preadesionefds"
            v-model="filters.preadesionefds"
          >
            <b-form-select-option :value="true">sì</b-form-select-option>
            <!-- <b-form-select-option :value="false">no</b-form-select-option> -->
            <b-form-select-option :value="null">scegli</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <!-- <b-form-group
          label="Adesione FDS"
          label-for="adesionefds"
          class="text-left mx-auto col mb-1"
        >
          <b-form-select
            class="my-1 shadow_6"
            name="adesionefds"
            v-model="filters.adesionefds"
          >
            <b-form-select-option :value="true">sì</b-form-select-option>
            <b-form-select-option :value="null">scegli</b-form-select-option>
          </b-form-select>
        </b-form-group> -->
        <b-form-group
          label="Pre-adesione al servizio FDS"
          label-for="preadesionefdser"
          class="text-left mx-auto col mb-1"
        >
          <b-form-select
            class="my-1 shadow_6"
            name="preadesionefdser"
            v-model="filters.preadesionefdser"
          >
            <b-form-select-option
              v-for="servOpt in fdservicesOptions"
              :key="servOpt.hashid"
              :value="servOpt.name"
              >{{ servOpt.name }}</b-form-select-option
            >
            <b-form-select-option :value="null"
              >scegli servizio fds</b-form-select-option
            >
          </b-form-select>
        </b-form-group>
        <b-form-group
          label="Adesione al servizio FDS"
          label-for="adesionefdser"
          class="text-left mx-auto col mb-1"
        >
          <b-form-select
            class="my-1 shadow_6"
            name="adesionefdser"
            v-model="filters.adesionefdser"
          >
            <b-form-select-option
              v-for="servOpt in fdservicesOptions"
              :key="servOpt.hashid"
              :value="servOpt.name"
              >{{ servOpt.name }}</b-form-select-option
            >
            <b-form-select-option :value="null"
              >scegli servizio fds</b-form-select-option
            >
          </b-form-select>
        </b-form-group>
        <b-form-group
          label="Ultimo login dal"
          label-for="logfrom"
          class="text-left mx-auto col mb-1"
        >
          <b-form-input
            class="my-1 shadow_6"
            name="logfrom"
            type="date"
            v-model="filters.logfrom"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label="Ultimo login al"
          label-for="logto"
          class="text-left mx-auto col mb-1"
        >
          <b-form-input
            class="my-1 shadow_6"
            name="logto"
            type="date"
            v-model="filters.logto"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label="Associata Federfarma"
          label-for="associata_ff"
          class="text-left mx-auto col mb-1"
        >
          <b-form-select
            class="my-1 shadow_6"
            name="associata_ff"
            v-model="filters.associata_ff"
          >
            <b-form-select-option :value="null">scegli...</b-form-select-option>
            <b-form-select-option :value="1">Sì</b-form-select-option>
            <b-form-select-option :value="false">No</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group
          v-if="filters.associata_ff === false"
          label="Associazione"
          label-for="farmacieunite"
          class="text-left mx-auto col mb-1"
        >
          <b-form-select
            class="my-1 shadow_6"
            name="farmacieunite"
            v-model="filters.farmacieunite"
          >
            <b-form-select-option :value="null">scegli...</b-form-select-option>
            <b-form-select-option :value="1"
              >Farmacie Unite</b-form-select-option
            >
            <b-form-select-option :value="false">Altro</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group
          label="Sottoscrizione"
          label-for="sottoscrizione"
          class="text-left mx-auto col mb-1"
        >
          <b-form-select
            class="my-1 shadow_6"
            name="sottoscrizione"
            v-model="filters.sottoscrizione"
          >
            <b-form-select-option :value="null">scegli...</b-form-select-option>
            <b-form-select-option :value="1">Sì</b-form-select-option>
            <b-form-select-option :value="2">No</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group
          label="Tipo Sottoscrizione"
          label-for="tipo_contratto"
          class="text-left col mb-1"
        >
          <b-form-select
            class="my-1 shadow_6"
            name="tipo_contratto"
            v-model="filters.tipo_contratto"
          >
            <b-form-select-option :value="null">scegli...</b-form-select-option>
            <b-form-select-option value="pnrr">PNRR</b-form-select-option>
            <b-form-select-option value="sperimentazione">Sperimentazione</b-form-select-option>
            <b-form-select-option value="standard">Standard</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-col></b-col>
        <b-col></b-col>
        <!-- </div> -->
      </b-row>

      <!-- <div class="col-12 d-flex flex-wrap"></div> -->
      <p v-if="results && results.length == 0" class="text-danger">
        Nessun risultato con questi parametri di ricerca
      </p>
      <div class="col-12 my-2 text-right">
        <b-button
          @click="clearFilters"
          size="sm"
          type="button"
          variant="warning text-white fw-bolder spacing_1 my-2"
        >
          Pulisci campi</b-button
        >
        <b-button
          @click="submitSearch"
          size="sm"
          type="button"
          variant="primary primary_gradient text-white fw-bolder spacing_1 my-2 ml-2"
          >Cerca</b-button
        >
      </div>
    </div>
    <b-spinner variant="primary" label="loading" v-if="loading"></b-spinner>
  </div>
</template>
<script>
import { supportService, adminService } from "@/_services";
import { mapState, mapActions, mapGetters } from "vuex";
import CompanyCard from "@/components/prenotazioni/CompanyCard.vue";
export default {
  name: "CompactSearch",
  components: { CompanyCard },
  props: [
    "pagefilter",
    "takefilter",
    "expocurrent",
    "sortby",
    "sortin",
    "sorting",
  ],
  data() {
    return {
      filters: {
        comune: null,
        provincia: null,
        regione: null,
        frazione: null,
        cap: null,
        denominazione: null,
        surname: null,
        partita_iva: null,
        codice_fiscale: null,
        codice_titolare: null,
        codice_tracciabilita: null,
        comunale: null,
        preadesionefds: null,
        preadesionefdser: null,
        adesionefdser: null,
        adesionefds: null,
        page: this.pagefilter,
        take: this.takefilter,
        sorter: "created_at",
        indesc: true,
        xls: undefined,
        logfrom: null,
        logto: null,
        associata_ff: null,
        farmacieunite: null,
        associazione: null,
        sottoscrizione: null,
        tipo_contratto: null
      },
      selected: {
        comune: null,
        provincia: null,
        regione: null,
      },
      results: null,
      loading: false,
      fdservicesOptions: null,
    };
  },
  computed: {
    ...mapState("utility", ["searching", "comuni", "province", "regioni"]),
    ...mapState("company", ["companyLoggedIn", "company"]),
    ...mapGetters({
      getCompany: "company/getCompany",
    }),
  },
  watch: {
    selected: {
      handler(val) {
        this.setData();
      },
      deep: true,
    },
    pagefilter: function (val) {
      this.submitSearch();
    },
    takefilter: function (val) {
      // //console.log("take filter changed");
      this.$nextTick(function () {
        // //console.log(val);
        this.submitSearch();
      });
      // this.submitSearch();
    },
    filters: {
      handler(val) {
        if (this.filters.xls !== true) {
          console.log("take filter changed");
          if (this.filters.associata_ff === 1) {
            this.filters.farmacieunite = null;
          }
          this.submitSearch();
        }
      },
      deep: true,
    },
    expocurrent: function (value) {
      // //console.log("value", value);
      if (value === true) {
        this.filters.xls = true;
        this.downloadSearch();
      }
    },
    // sortby: function (val) {
    //   console.log("sortby val", val);
    //   this.filters.sorter = val;
    //   this.submitSearch();
    // },
    // sortin: function (val) {
    //   this.filters.indesc = val;
    //   this.submitSearch();
    // },
    sorting: {
      handler(val) {
        var doupdate = false;
        if (val.sort !== this.filters.sorter) {
          this.filters.sorter = val.sort;
          doupdate = true;
        }
        if (val.indesc !== this.filters.indesc) {
          this.filters.indesc = val.indesc;
          doupdate = true;
        }
        if (doupdate) {
          this.submitSearch();
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("utility", ["getComuni", "getProvince", "getRegioni"]),
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    setData() {
      if (this.selected.comune) {
        this.filters.comune = this.selected.comune.comune;
      }
      this.filters.regione = this.selected.regione;
      this.filters.provincia = this.selected.provincia;
    },
    submitSearch() {
      this.loading = true;
      this.filters.take = this.takefilter;
      this.filters.page = this.pagefilter;
      var self = this;
      supportService
        .searchCompany(this.filters)
        .then(function (response) {
          self.results = response.data.data;
          self.$emit("new-selection", self.results);
          self.loading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    clearFilters() {
      this.filters = {
        comune: null,
        provincia: null,
        regione: null,
        frazione: null,
        cap: null,
        denominazione: null,
        surname: null,
        partita_iva: null,
        codice_fiscale: null,
        codice_titolare: null,
        codice_tracciabilita: null,
        comunale: null,
        preadesionefds: null,
        preadesionefdser: null,
        adesionefdser: null,
        adesionefds: null,
        page: 1,
        take: this.takefilter,
        logfrom: null,
        logto: null,
        associata_ff: null,
        farmacieunite: null,
        sottoscrizione: null,
        tipo_contratto: null
      };
      this.selected.regione = null;
      this.selected.provincia = null;
      this.selected.comune = null;
    },
    getFdservices() {
      var self = this;
      adminService
        .getAllFdservices()
        .then(function (response) {
          self.fdservicesOptions = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    downloadSearch() {
      this.loading = true;
      var self = this;
      adminService
        .downloadSelectedCompany(this.filters)
        .then(function (response) {
          self.$emit("action-ended");
          self.loading = false;
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Report_selezionate.xlsx";
          link.click();
          self.filters.xls = undefined;
        })
        .catch(function (error) {
          self.filters.xls = undefined;
          self.loading = false;
          self.$emit("action-ended");
          console.log(error);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile scaricare il report");
        });
    },
  },
  created() {
    // if (!this.regioni) {
    this.getRegioni();
    this.submitSearch();
    // }
  },
  mounted() {
    this.getFdservices();
  },
};
</script>
