<template>
  <div class="col-12 py-4">
    <b-row align-v="center" class="justify-content-center">
      <b-col></b-col>
      <b-col>
        <b-button-group class="mb-3 bg-line-grey rounded-pill">
          <b-button
            class="fw-bolder text-capitalize"
            :size="$root.isMobile ? 'sm' : ''"
            @click="activePanel = 'attivita'"
            :variant="
              activePanel === 'attivita'
                ? 'primary primary_gradient text-white rounded-pill'
                : 'bg-light text-primary'
            "
            >attività</b-button
          >
          <b-button
            class="fw-bolder text-capitalize"
            :size="$root.isMobile ? 'sm' : ''"
            @click="activePanel = 'questionari'"
            :variant="
              activePanel === 'questionari'
                ? 'primary primary_gradient text-white rounded-pill'
                : 'bg-light text-primary'
            "
            >questionari</b-button
          >
          <b-button
            v-if="
              !user.permessi.includes('gestire aziende propria regione') &&
              !user.permessi.includes('gestire aziende propria asl')
            "
            class="fw-bolder text-capitalize"
            :size="$root.isMobile ? 'sm' : ''"
            @click="activePanel = 'rendicontazione'"
            :variant="
              activePanel === 'rendicontazione'
                ? 'primary primary_gradient text-white rounded-pill'
                : 'bg-light text-primary'
            "
            >rendicontazione</b-button
          >
        </b-button-group>
      </b-col>
      <b-col class="text-right">
        <router-link
          v-if="
            !user.permessi.includes('gestire aziende propria regione') &&
            !user.permessi.includes('gestire aziende propria asl')
          "
          class="fw-bolder text-primary no__hover"
          :to="{ name: 'gestione aziende' }"
        >
          Assegna
          {{ activePanel !== "rendicontazione" ? activePanel : "attività" }}
          <b-icon
            icon="arrow-right-circle-fill"
            variant="primary"
            v-b-tooltip.hover
            title="Gestione Aziende"
          ></b-icon>
        </router-link>
      </b-col>
    </b-row>
    <transition-group name="list" mode="out-in" appear>
      <div v-if="activePanel === 'attivita'" key="attivita">
        <h6
          class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
        >
          Attività
        </h6>
        <p class="text-justify text-medium-grey fw-bolder">
          Clicca su una riga per vederne i dettagli. Per creare una nuova
          attività clicca su "nuova attività".
        </p>
        <div class="d-flex justify-content-between">
          <b-button
            v-if="
              !user.permessi.includes('gestire aziende propria regione') &&
              !user.permessi.includes('gestire aziende propria asl')
            "
            variant="outline-primary shadow_6 rounded-pill border-white"
            class="ml-0 mr-auto fw-bolder"
            @click="$refs['modal-newactivity'].show()"
          >
            <b-icon icon="plus"></b-icon> nuova attività
          </b-button>

          <b-input-group
            size="sm"
            class="mt-2 col-lg-4 col-sm-6 ml-auto mr-0 shadow_3 rounded-pill"
          >
            <b-form-input
              class="align-items-center align-content-center"
              v-model="filterSetted.search"
              type="search"
              placeholder="Cerca attività.."
              @input="fetchData"
            >
            </b-form-input>

            <b-input-group-append>
              <span class="mx-2">
                <b-icon icon="search"></b-icon>
              </span>
            </b-input-group-append>
          </b-input-group>
        </div>
        <div class="mt-3">
          <b-table
            borderless
            hover
            responsive
            table-variant="white text-info"
            :fields="fields"
            :items="activities"
            :small="$root.isMobile"
            selectable
            select-mode="single"
            @row-selected="onRowSelected"
            ref="actable"
            :busy="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Attendere caricamento...</strong>
              </div>
            </template>
            <template #cell(name)="row" class=" text-left">
              <span
                class="text-secondary text-justify text-capitalize text-left fw-bolder"
                >{{ row.item.name }}</span
              >
            </template>

            <template #cell(description)="row">
              <span class="text-secondary text-justify fw-bolder">{{
                row.item.description
              }}</span>
            </template>
            <template #cell(tot_surveys)="row">
              <!-- <span class="text-secondary text-justify fw-bolder">creati {{ row.item.tot_surveys }} <br /></span><span
                class="text-secondary text-justify fw-bolder">somm. {{ row.item.filled }}</span> -->
              <span class="text-secondary text-justify fw-bolder"
                >{{ row.item.tot_surveys }}</span
              >
            </template>
            <template #cell(created)="row">
              <!-- <span class="text-secondary text-justify fw-bolder">creati {{ row.item.tot_surveys }} <br /></span><span
                class="text-secondary text-justify fw-bolder">somm. {{ row.item.filled }}</span> -->
              <span class="text-secondary text-justify fw-bolder"
                >{{ row.item.created ? formatData(row.item.created) : '-'}}</span
              >
            </template>
            <template #cell(created_by)="row">
              <span
                class="text-secondary text-justify fw-bolder"
                v-if="row.item.created_by == null"
                >admin</span
              >
              <router-link
                v-else
                :to="{
                  name: 'dettaglio azienda',
                  params: { company: row.item.created_by },
                }"
                class="text-primary fw-bolder"
                v-b-tooltip.hover
                title="vai a panoramica"
                >{{ row.item.creator_company }}</router-link
              >
            </template>
            <template #cell(filled)="row">
              <!-- <span class="text-secondary text-justify fw-bolder">creati {{ row.item.tot_surveys }} <br /></span><span
                class="text-secondary text-justify fw-bolder">somm. {{ row.item.filled }}</span> -->
              <span class="text-secondary text-justify fw-bolder"
                >{{ row.item.filled }}</span
              >
            </template>
            <template #cell(active)="row">
              <b-form-checkbox
                v-model="row.item.enabled"
                name="check-button"
                :state="row.item.enabled == 1"
                :value="1"
                :unchecked-value="0"
                switch
                @change="selectChangeStatus(row)"
              >
              </b-form-checkbox>
            </template>
            <!-- <template #cell(azioni)="row">
              <b-button
                variant="info info_gradient fw-bolder rounded-pill px-3"
                size="sm"
                @click="$refs.actable.selectRow(row.index)"
                >Dettaglio</b-button
              >
            </template> -->
          </b-table>
          <p v-if="activities && activities.length == 0">
            Nessuna attività qui
          </p>
          <b-modal
            ref="modal-newactivity"
            title="Crea Nuova Attività"
            size="lg"
            hide-footer
          >
            <NuovaAttivita
              v-on:activity-created="handleNewActivity($event)"
              class="px-4 py-3"
            />
          </b-modal>
          <b-modal
            ref="modal-statusconfirm"
            title="Conferma operazione"
            size="lg"
            hide-footer
            @hidden="cancelOp"
          >
            <div v-if="selectedItem !== null">
              <h5 class="fw-bolder">
                Sei sicuro di voler
                {{
                  activities[selectedItem].enabled === 1
                    ? "attivare "
                    : "disattivare "
                }}
                "{{ activities[selectedItem].name }}" ?
              </h5>
              <p v-if="activities[selectedItem].enabled === 1">
                Attivando l'attività sarà disponibile per le aziende a cui è
                stata assegnata.
              </p>
              <p v-else>
                Disattivando l'attività la stessa non sarà più disponibile per
                le aziende a cui era stata assegnata.
              </p>
              <div class="my-4 text-center">
                <b-button variant="outline-danger m-1" @click="cancelOp"
                  >No, annulla</b-button
                >
                <b-button variant="info info_gradient m-1" @click="changeStatus"
                  >Sì, procedi</b-button
                >
              </div>
            </div>
          </b-modal>
        </div>
        <div v-if="showDetails">
          <ActivityDetails
            v-if="selectedActivity"
            :activity="selectedActivity"
            :asAdmin="true"
          />
          <span ref="activitydetail"></span>
          <div class="text-left pb-4">
            <b-button
              variant="secondary secondary_gradient fw-bolder"
              @click="showAbilitate"
              >Vedi elenco abilitate
            </b-button>
            <b-modal
              v-if="selectedActivity"
              id="modal-comp-list"
              :title="'Elenco Abilitate ' + selectedActivity.name"
              hide-footer
              size="xl"
            >
              <template v-if="abilitate">
                <p
                  class="text-left text-capitalize"
                  v-for="(comp, ind) in abilitate"
                  :key="ind"
                >
                  <strong>{{ ind + 1 }}. {{ comp.denominazione }}</strong
                  >, {{ comp.regione }}, {{ comp.provincia }}, {{ comp.comune }}
                </p>
              </template>
              <template v-else>
                <p
                  class="text-left text-capitalize"
                  v-for="(comp, ind) in selectedActivity.enabled_companies"
                  :key="ind"
                >
                  <strong>{{ ind + 1 }}. {{ comp.denominazione }}</strong
                  >, {{ comp.regione }}, {{ comp.provincia }}, {{ comp.comune }}
                </p>
              </template>
            </b-modal>
          </div>
        </div>
      </div>
      <div v-else-if="activePanel === 'questionari'" key="questionari">
        <h6
          class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
        >
          Questionari
        </h6>
        <p class="text-justify text-medium-grey fw-bolder">
          Clicca su una riga per vederne i dettagli. Per creare un nuovo
          questionario clicca su "nuovo questionario".
        </p>
        <AdminQuestionari />
      </div>
      <div v-else-if="activePanel === 'rendicontazione'" key="rendicontazione">
        <h6
          class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
        >
          Rendicontazione
        </h6>
        <p class="text-justify text-medium-grey fw-bolder">
          Esporta i report utili alla rendicontazione per una specifica regione.
        </p>
        <ExportRendicontazioneVeneto />
      </div>
    </transition-group>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { supportService, adminService } from "@/_services";
import { mapState, mapActions } from "vuex";
import NuovaAttivita from "@/components/admin/attivita/NuovaAttivita.vue";
import AdminQuestionari from "@/components/admin/attivita/AdminQuestionari.vue";
import ExportRendicontazioneVeneto from "@/components/admin/attivita/ExportRendicontazioneVeneto.vue";
import ActivityDetails from "@/components/attivita/ActivityDetails.vue";
export default {
  name: "AdminHomeAttivita",
  components: {
    NuovaAttivita,
    AdminQuestionari,
    ActivityDetails,
    ExportRendicontazioneVeneto,
  },
  data() {
    return {
      filterSetted: { search: null },
      fields: [
        { key: "name", label: "Nome", class:"text-left" },
        { key: "tot_surveys", label: "Num. Questionari" },
        { key: "created", label: "Creato Il" },
        //{ key: "created_by", label: "Creato Da" },
        { key: "filled", label: "Questionari Somministrati" },
        // { key: "data", label: "Data", sortable: true },
        { key: "active", label: "Stato" },
        // { key: "azioni", label: "" },
      ],
      activities: null,
      activePanel: "attivita",
      selectedActivity: null,
      showDetails: false,
      selectedItem: null,
      abilitate: null,
      isBusy: true,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    formatData(data) {
      return moment(data).format("DD MMM YYYY");
    },
    fetchData() {
      this.isBusy = true;
      const self = this;
      supportService
        .getActivities(this.filterSetted)
        .then(function (res) {
          self.activities = res.data.data;
          self.isBusy = false;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le attività");
          console.log(err);
          self.isBusy = false;
        });
      //
    },
    handleSearch() {
      if (this.filterSetted.search && this.filterSetted.search.length >= 3) {
        this.fetchData();
      }
    },
    handleNewActivity(resp) {
      this.$refs["modal-newactivity"].hide();
      window.scrollTo(0, 0);
      if (resp == true) {
        this.successAlert("Attività creata con successo!");
        this.fetchData();
      } else {
        this.errorAlert("Non è stato possibile creare l'attività");
      }
    },
    selectChangeStatus(row) {
      //console.log(row);
      this.selectedItem = row.index;
      this.$refs["modal-statusconfirm"].show();
    },
    cancelOp() {
      this.$refs["modal-statusconfirm"].hide();
      this.fetchData();
    },
    changeStatus() {
      var formData = {
        hash: this.activities[this.selectedItem].hashid,
        form: {
          enabled: this.activities[this.selectedItem].enabled,
        },
      };
      const self = this;
      adminService
        .updateActivityStatus(formData)
        .then(function (res) {
          self.$refs["modal-statusconfirm"].hide();
          window.scrollTo(0, 0);
          self.successAlert("Stato attività aggiornato con successo!");
          self.fetchData();
        })
        .catch(function (err) {
          console.log(err);
          self.$refs["modal-statusconfirm"].hide();
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile aggiornare lo stato attività");
          self.fetchData();
        });
    },
    onRowSelected(items) {
      this.$router.push({
        name: "dettagli attività",
        params: { activity: items[0].hashid },
      });
      // this.selectedActivity = items[0];
      // this.showDetails = true;
      // this.$nextTick(function () {
      //   this.$refs["activitydetail"].scrollIntoView();
      // });
    },
    showAbilitate() {
      this.getAbilitate();
      this.$bvModal.show("modal-comp-list");
    },
    getAbilitate() {
      console.log(this.selectedActivity);
      var self = this;
      var dataFilter = {
        hash: this.selectedActivity.hashid,
      };
      adminService
        .getAbilitateForActivity(dataFilter)
        .then(function (res) {
          self.abilitate = res.data.data;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le attività");
          console.log(err);
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
