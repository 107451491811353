<template>
  <div class="register pb-4">
    <h2>Registrazione</h2>
    <div>
      <b-card no-body class="col-lg-10 col-sm-12 mx-auto border-0">
        <b-tabs
          pills
          :card="tab_index !== 2"
          no-key-nav
          v-model="tab_index"
          class="bg-white"
        >
          <b-tab
            :title="
              tab_index === 0 ? 'Seleziona il tipo di registrazione' : '   '
            "
            title-link-class="bg-white border-white text-prim-grad-1 fw-bolder"
          >
            <div class="col-12 mx-auto d-flex">
              <b-form-radio-group
                label="Individual radios"
                class="col-12 mx-auto d-flex"
              >
                <div class="col-6 ml-0 mr-auto text-left">
                  <b-form-radio
                    class="fw-bolder text-primary"
                    v-model="form.type"
                    name="user_type"
                    :value="0"
                    >Cittadino
                  </b-form-radio>
                  <p class="text-font-light-grey">
                    Accedi alla tua cartella clinica, registra i tuoi familiari,
                    mettiti in contatto con il tuo Medico o Farmacista, e molto
                    altro.
                  </p>
                  <p class="text-secondary">
                    <span
                      target="_blank"
                      class="point"
                      @click="$bvModal.show(informativa[0].url)"
                    >
                      <u>
                        Consulta l'informativa sul trattamento dei dati
                        personali del cittadino</u
                      ></span
                    >
                  </p>
                </div>
                <div class="col-6 ml-0 mr-auto text-left">
                  <b-form-radio
                    class="fw-bolder text-primary"
                    v-model="form.type"
                    name="user_type"
                    :value="1"
                    >Operatore sanitario</b-form-radio
                  >
                  <p class="text-font-light-grey">
                    Mettiti in contatto con tuoi pazienti e organizza i tuoi
                    Appuntamenti e tele consulti in tutta sicurezza.
                  </p>
                  <p class="text-secondary">
                    <span
                      target="_blank"
                      class="point"
                      @click="$bvModal.show(informativa[1].url)"
                    >
                      <u>
                        Consulta l'informativa sul trattamento dei dati
                        personali dell'operatore sanitario</u
                      ></span
                    >
                  </p>
                </div>
              </b-form-radio-group>
            </div>
            <br />
            <div class="col-12 text-right mt-3">
              <b-button
                type="button"
                variant="outline-secondary mx-2"
                @click="$router.push('/login')"
                >Indietro
              </b-button>
              <b-button
                type="button"
                variant="primary text-white primary_gradient mx-2"
                :disabled="form.type == null"
                @click="tab_index++"
                >Avanti
                <b-icon icon="arrow-right"></b-icon>
              </b-button>
            </div>
          </b-tab>
          <b-tab
            :title="tab_index === 1 ? 'Inserisci i tuoi dati' : '   '"
            title-link-class="bg-white border-white text-prim-grad-1 fw-bolder"
          >
            <b-form @submit.prevent="tab_index++">
              <!-- <div class="mx-auto"> -->
              <b-row>
                <b-col class="px-0">
                  <b-form-group
                    label="Titolo"
                    label-for="titolo"
                    class="text-left fs-6 text-font-grey mx-auto col-12 px-0"
                  >
                    <b-form-select
                      class="my-3 shadow_6 text-capitalize"
                      :options="titleOptions"
                      v-model="anagrafica.title"
                    >
                      <b-form-select-option disabled :value="null"
                        >Titolo</b-form-select-option
                      >
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col class="d-none d-md-block"></b-col>
              </b-row>
              <div class="col-12 d-flex flex-wrap px-0">
                <b-form-group
                  label="Nome*"
                  label-for="name"
                  class="text-left fs-6 text-font-grey mx-auto col-12 px-1 col-md-6 px-md-2"
                >
                  <b-form-input
                    class="my-3 shadow_6 text-capitalize"
                    name="name"
                    type="text"
                    placeholder="nome..."
                    v-model="anagrafica.name"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label="Cognome*"
                  label-for="surname"
                  class="text-left fs-6 text-font-grey mx-auto col-12 px-1 col-md-6 px-md-2"
                >
                  <b-form-input
                    class="my-3 shadow_6 text-capitalize"
                    name="surname"
                    type="text"
                    placeholder="cognome..."
                    v-model="anagrafica.surname"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <template v-if="form.type === 0">
                <div class="col-12 d-flex flex-wrap px-0">
                  <b-form-group
                    label="Codice fiscale*"
                    label-for="cf"
                    class="text-left fs-6 text-font-grey mx-auto col-12 px-1 col-md-6 px-md-2"
                  >
                    <b-form-input
                      class="my-3 shadow_6 text-uppercase"
                      name="cf"
                      type="text"
                      placeholder="codice fiscale..."
                      maxlength="16"
                      minlength="16"
                      v-model="anagrafica.cf"
                      :state="anagrafica.cf == null ? null : validCf"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Numero Tessera Sanitaria"
                    label-for="ts_number"
                    class="text-left fs-6 text-font-grey mx-auto col-12 px-1 col-md-6 px-md-2"
                  >
                    <b-form-input
                      class="my-3 shadow_6"
                      name="ts_number"
                      type="text"
                      placeholder="numero tessera..."
                      v-model="anagrafica.ts_number"
                      maxlength="20"
                      minlength="20"
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-12 d-flex flex-wrap px-0">
                  <b-form-group
                    label="Data di nascita*"
                    label-for="birthday"
                    class="text-left fs-6 text-font-grey mx-auto col-12 px-1 col-md-6 px-md-2"
                  >
                    <b-form-input
                      class="my-3 shadow_6"
                      name="birthday"
                      type="date"
                      v-model="anagrafica.birthday"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Genere"
                    label-for="gender"
                    class="text-left fs-6 text-font-grey mx-auto col-12 px-1 col-md-6 px-md-2"
                  >
                    <b-form-radio-group
                      v-model="anagrafica.gender"
                      name="gender-options"
                      class="d-flex"
                    >
                      <b-form-radio value="maschile">uomo</b-form-radio>
                      <b-form-radio value="femminile">donna</b-form-radio>
                      <b-form-radio :value="null">non indicare</b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </div>
                <div class="col-12 d-flex flex-wrap px-0">
                  <b-form-group
                    label="Nazione di nascita*"
                    label-for="nazione_di_nascita"
                    class="text-left fs-6 text-font-grey mx-auto col-12 px-1 col-md-6 px-md-2"
                  >
                    <b-form-select
                      class="my-3 shadow_6 text-capitalize"
                      :options="nazioniOptions"
                      name="nazion"
                      v-model="anagrafica.nazione_nascita"
                      @change="getProvince(selected)"
                      required
                    >
                    </b-form-select>
                  </b-form-group>
                  <b-form-group
                    label="Regione di residenza*"
                    label-for="regione"
                    class="text-left fs-6 text-font-grey mx-auto col-12 px-1 col-md-6 px-md-2"
                  >
                    <b-form-select
                      class="my-3 shadow_6 text-capitalize"
                      :options="regioni"
                      name="regione"
                      v-model="selected.regione"
                      @change="getProvince(selected)"
                      required
                    >
                      <b-form-select-option :value="null"
                        >Seleziona Regione</b-form-select-option
                      >
                    </b-form-select>
                  </b-form-group>
                  <!-- </div>

                <div
                  class="col-12 d-flex flex-wrap px-0"
                  
                > -->
                  <b-form-group
                    label="Provincia di residenza*"
                    label-for="provincia"
                    class="text-left fs-6 text-font-grey mx-auto col-12 px-1 col-md-6 px-md-2"
                  >
                    <b-form-select
                      class="my-3 shadow_6 text-capitalize"
                      :options="province"
                      name="provincia"
                      v-model="selected.provincia"
                      @change="getComuni(selected)"
                      required
                    >
                      <b-form-select-option :value="null"
                        >Seleziona Provincia</b-form-select-option
                      >
                    </b-form-select>
                  </b-form-group>

                  <b-form-group
                    label="Comune di residenza*"
                    label-for="comune"
                    class="text-left fs-6 text-font-grey mx-auto col-12 px-1 col-md-6 px-md-2"
                  >
                    <b-form-select
                      class="my-3 shadow_6 text-capitalize"
                      name="comune"
                      v-model="selected.comune"
                      required
                    >
                      <b-form-select-option
                        v-for="(com, ind) in comuni"
                        :key="ind"
                        :value="com"
                        @change="setDomicilioData"
                        :selected="findComune(com)"
                        >{{ com.comune }}</b-form-select-option
                      >
                      <b-form-select-option :value="null"
                        >Seleziona Comune</b-form-select-option
                      >
                    </b-form-select>
                  </b-form-group>
                  <!-- </div>
                <div
                  class="col-12 d-flex flex-wrap px-0"
                  
                > -->
                  <b-form-group
                    label="Indirizzo e numero civico"
                    label-for="address"
                    class="text-left fs-6 text-font-grey mx-auto col-12 px-1 col-md-6 px-md-2"
                  >
                    <b-form-input
                      class="my-3 shadow_6"
                      name="address"
                      type="text"
                      placeholder="indirizzo..."
                      v-model="anagrafica.address"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Cap"
                    label-for="cap"
                    class="text-left fs-6 text-font-grey mr-auto col-12 px-1 col-md-6 px-md-2"
                  >
                    <b-form-input
                      class="my-3 shadow_6"
                      name="cap"
                      type="text"
                      placeholder="cap..."
                      v-model="anagrafica.cap"
                      minlength="5"
                      maxlength="5"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Numero di telefono domicilio"
                    label-for="home_number"
                    class="text-left fs-6 text-font-grey mr-auto col-12 col-md-6 px-md-2"
                  >
                    <b-input-group class="">
                      <b-form-input
                        class="my-3 shadow_6"
                        name="home_number"
                        type="text"
                        placeholder="telefono domicilio..."
                        v-model="anagrafica.home_number"
                        minlength="9"
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </div>
              </template>
              <div class="col-12 d-flex flex-wrap px-0">
                <b-form-group
                  label="Numero di telefono*"
                  label-for="tel_number"
                  class="text-left fs-6 text-font-grey mx-auto col-12 px-1 col-md-6 px-md-2"
                >
                  <b-input-group class="">
                    <b-input-group-prepend>
                      <h6 class="my-4 pt-2 pr-1 text-prim-grad-1">+39</h6>
                    </b-input-group-prepend>
                    <b-form-input
                      class="my-3 shadow_6"
                      name="tel_number"
                      type="text"
                      placeholder="telefono..."
                      v-model="anagrafica.tel_number"
                      minlength="9"
                      required
                      :state="
                        anagrafica.tel_number !== null ? validNumber : null
                      "
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group
                  label="Email*"
                  label-for="email"
                  class="text-left fs-6 text-font-grey mx-auto col-12 col-md-6 px-1 px-md-2"
                >
                  <b-form-input
                    v-model="form.email"
                    placeholder="email..."
                    class="my-4 shadow_6"
                    name="email"
                    :state="form.email == null ? null : validEmail"
                    :disabled="!anagrafica.name || !anagrafica.surname"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12 d-flex flex-wrap px-0">
                <b-form-group
                  label="Password*"
                  label-for="password"
                  class="text-left fs-6 text-font-grey mx-auto col-12 px-1 col-md-6 px-md-2"
                >
                  <b-form-input
                    class="my-3 shadow_6"
                    name="password"
                    type="password"
                    v-model="form.password"
                    placeholder="password..."
                    :state="form.password == null ? null : validatePassword"
                    required
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  label="Conferma password*"
                  label-for="password"
                  class="text-left fs-6 text-font-grey mx-auto col-12 px-1 col-md-6 px-md-2"
                >
                  <b-form-input
                    class="my-3 shadow_6"
                    name="password_confirmation"
                    type="password"
                    v-model="form.password_confirmation"
                    placeholder="conferma password..."
                    :state="form.password == null ? null : validateConfirm"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  v-if="form.type === 1"
                  class="text-left fs-6 text-font-grey mx-auto col-12 px-1 col-md-6 ml-md-0 px-md-2"
                  description="Inserisci il codice di almeno 10 cifre fornito dalla tua azienda. Il codice azienda si trova nella sezione 'operatori' della pagina di impostazioni."
                >
                  <label for="codice_azienda"
                    >Codice azienda*
                    <!-- <b-icon
                    icon="info-circle"
                    id="info_code"
                    variant="font-grey"
                  ></b-icon>
                  <b-tooltip target="info_code"
                    >Il codice azienda si trova tra le impostazioni
                    dell'azienda</b-tooltip
                  > -->
                  </label>
                  <b-form-input
                    v-model="form.company_code"
                    placeholder="codice azienda..."
                    class="my-3 shadow_6"
                    name="codice_azienda"
                    :state="form.company_code == null ? null : canSubmit"
                    required
                    v-on:input="checkCode"
                  ></b-form-input>
                </b-form-group>
                <p class="text-left text-medium-grey fs-6">
                  La password non può includere il nome né il cognome e deve
                  contenere almeno 8 caratteri, di cui almeno un numero, una
                  maiuscola, una minuscola ed un
                  <span
                    v-b-tooltip
                    title="Devi usare almeno un caratterere tra ! @ # $ % ^ & * ) ( + = . _ -"
                    >carattere speciale</span
                  >.
                </p>
              </div>
              <p class="text-secondary text-center">
                <span
                  target="_blank"
                  class="point"
                  @click="$bvModal.show(informativa[form.type].url)"
                >
                  <u>
                    Consulta l'informativa sul trattamento dei dati personali</u
                  ></span
                >
              </p>
              <br />
              <div class="col-12 text-right mt-3 px-0">
                <b-button
                  type="button"
                  variant="outline-secondary mx-2"
                  @click="tab_index--"
                  >Indietro</b-button
                >
                <b-button
                  type="submit"
                  variant="primary text-white primary_gradient mx-2"
                  :disabled="!enableSecondBtn"
                >
                  Avanti
                  <b-icon icon="arrow-right"></b-icon>
                </b-button>
              </div>
            </b-form>
          </b-tab>
          <b-tab
            title=""
            no-nav-style
            title-link-class="bg-white border-white text-prim-grad-1 fw-bolder"
          >
            <div class="col-12">
              <h6
                class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
              >
                Informativa Privacy
              </h6>
              <p class="text-secondary text-justify">
                Per procedere alla registrazione è necessario
                <span
                  target="_blank"
                  class="point"
                  @click="$bvModal.show(informativa[form.type].url)"
                >
                  <u>
                    leggere ed accettare l'informativa sul trattamento dei dati
                    personali</u
                  ></span
                >
              </p>
              <b-row class="text-leftmb-2">
                <!-- <b-form-checkbox
                  class="text-font-light-grey ml-0 mr-auto mx-lg-1"
                  name="data_consense"
                  :value="false"
                  :unchecked-value="false"
                  v-model="form.data_consense"
                  :state="false"
                >
                  Non acconsento
                </b-form-checkbox> -->
                <b-form-checkbox
                  class="text-font-light-grey ml-0 mr-auto mx-lg-1"
                  name="data_consense"
                  :value="true"
                  :unchecked-value="false"
                  v-model="form.data_consense"
                  :state="form.data_consense == true"
                >
                  Ho letto e compreso
                  <span
                    target="_blank"
                    class="point"
                    @click="$bvModal.show(informativa[form.type].url)"
                  >
                    <u> l'informativa privacy</u></span
                  >
                </b-form-checkbox>
              </b-row>
              <b-row class="text-leftmb-2">
                <!-- <b-form-checkbox
                  class="text-font-light-grey ml-0 mr-auto mx-lg-1"
                  name="share_consense"
                  :value="false"
                  :unchecked-value="false"
                  v-model="form.share_consense"
                  :state="false"
                >
                  Non acconsento
                </b-form-checkbox> -->
                <!-- <b-form-checkbox
                  class="text-font-light-grey ml-0 mr-auto mx-lg-1"
                  name="share_consense"
                  :value="true"
                  :unchecked-value="false"
                  v-model="form.share_consense"
                  :state="form.share_consense == true"
                >
                  Ho letto e accetto i
                  <span
                    target="_blank"
                    class="point"
                    @click="$bvModal.show(tc[form.type].url)"
                  >
                    <u> Termini e Condizioni d’uso </u></span
                  >
                </b-form-checkbox> -->
              </b-row>
            </div>
            <div class="col-12 mt-4">
              <h6
                class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
              >
                Termini e condizioni
              </h6>
              <p class="text-secondary text-justify">
                Per procedere alla registrazione è necessario
                <span
                  target="_blank"
                  class="point"
                  @click="$bvModal.show(tc[form.type].url)"
                >
                  <u
                    >leggere ed accettare l'informativa sul termini e condizioni
                    di utilizzo</u
                  ></span
                >.
              </p>
              <b-row class="text-left">
                <b-form-checkbox
                  class="text-font-light-grey ml-0 mr-auto mx-lg-1"
                  name="share_consense"
                  :value="true"
                  :unchecked-value="false"
                  v-model="form.share_consense"
                  :state="form.share_consense == true"
                >
                  Ho letto e accetto
                  <span
                    target="_blank"
                    class="point"
                    @click="$bvModal.show(tc[form.type].url)"
                  >
                    <u> Termini e Condizioni d’uso </u></span
                  >
                </b-form-checkbox>
              </b-row>
              <b-row class="text-left">
                <!-- <b-form-checkbox
                  class="text-font-light-grey mx-1"
                  name="charge_consense"
                  :value="false"
                  :unchecked-value="false"
                  v-model="form.charge_consense"
                  :state="false"
                >
                  Non acconsento
                </b-form-checkbox> -->
                <b-form-checkbox
                  class="text-font-light-grey mx-1"
                  name="charge_consense"
                  :value="true"
                  :unchecked-value="false"
                  v-model="form.charge_consense"
                  :state="form.charge_consense == true"
                >
                  Ai sensi dell’articolo 1341 e ss c.c. accetto espressamente le
                  previsioni di cui agli articoli 2, 7, 8, 10, 12 dei presenti
                  Termini e Condizioni d’uso
                </b-form-checkbox>
              </b-row>
            </div>
            <br />
            <div class="col-12 text-right mt-3">
              <b-button
                type="button"
                variant="outline-secondary mx-2"
                @click="tab_index--"
                >Indietro</b-button
              >
              <b-button
                type="button"
                variant="primary text-white primary_gradient mx-2"
                :disabled="!enableThirdBtn"
                @click.prevent="OnSubmit"
                >Completa
              </b-button>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
    <b-modal
      size="xl"
      centered
      id="info_cittadino"
      title="Informativa Privacy"
      hide-footer
    >
      <InformativaRegistrazioneCittadini></InformativaRegistrazioneCittadini>
    </b-modal>
    <b-modal
      size="xl"
      centered
      id="info_farmacista"
      title="Informativa Privacy"
      hide-footer
    >
      <InformativaRegistrazioneFarmacisti></InformativaRegistrazioneFarmacisti>
    </b-modal>
    <b-modal
      size="xl"
      centered
      id="tc_cittadino"
      title="Termini e Condizioni"
      hide-footer
    >
      <TerminiCondizioniCittadini></TerminiCondizioniCittadini>
    </b-modal>
    <b-modal
      size="xl"
      centered
      id="tc_farmacista"
      title="Termini e Condizioni"
      hide-footer
    >
      <TerminiCondizioniFarmacisti></TerminiCondizioniFarmacisti>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { utilityService } from "@/_services";
import InformativaRegistrazioneFarmacisti from "@/components/profile/informative/InformativaRegistrazioneFarmacisti.vue";
import InformativaRegistrazioneCittadini from "@/components/profile/informative/InformativaRegistrazioneCittadini.vue";
import TerminiCondizioniCittadini from "@/components/profile/informative/TerminiCondizioniCittadini.vue";
import TerminiCondizioniFarmacisti from "@/components/profile/informative/TerminiCondizioniFarmacisti.vue";
export default {
  name: "RegisterPage",
  components: {
    InformativaRegistrazioneFarmacisti,
    InformativaRegistrazioneCittadini,
    TerminiCondizioniCittadini,
    TerminiCondizioniFarmacisti,
  },
  data() {
    return {
      form: {
        email: null,
        password: null,
        password_confirmation: null,
        data_consense: false,
        share_consense: false,
        notification_consense: true,
        charge_consense: false,
        type: null,
        // company_code: null,
        anagrafica: null,
      },
      anagrafica: {
        regione: null,
        provincia: null,
        comune: null,
        cap: null,
        address: null,
        name: null,
        surname: null,
        cf: null,
        gender: null,
        birthday: null,
        ts_number: null,
        tel_number: null,
        home_number: null,
        title: null,
        nazione_nascita: "italia",
      },
      titleOptions: ["Sig.", "Sig.ra", "Dott.", "Dott.ssa"],
      nazioni: [],
      informativa: {
        0: {
          text: " informativa cittadino",
          url: "info_cittadino",
        },
        1: {
          text: " informativa farmacista",
          url: "info_farmacista",
        },
      },
      tc: {
        0: {
          text: " tc cittadino",
          url: "tc_cittadino",
        },
        1: {
          text: " tc farmacista",
          url: "tc_farmacista",
        },
      },
      has_minimum_lenth: true,
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      has_name: false,
      has_surname: false,
      tab_index: 0,
      canSubmit: false,
      selected: {
        regione: null,
        provincia: null,
        comune: null,
      },
      cfMonths: {
        A: "01",
        B: "02",
        C: "03",
        D: "04",
        E: "05",
        H: "06",
        L: "07",
        M: "08",
        P: "09",
        R: "10",
        S: "11",
        T: "12",
        a: "01",
        b: "02",
        c: "03",
        d: "04",
        e: "05",
        h: "06",
        l: "07",
        m: "08",
        p: "09",
        r: "10",
        s: "11",
        t: "12",
      },
      infoLinks: {
        box1: process.env.VUE_APP_LINK_INFO_REGISTRAZIONE_BOX1,
        box2: process.env.VUE_APP_LINK_INFO_REGISTRAZIONE_BOX2,
        box3: process.env.VUE_APP_LINK_INFO_REGISTRAZIONE_BOX3,
      },
    };
  },
  watch: {
    selected: {
      handler(val) {
        if (val.comune !== null) {
          this.anagrafica.comune = this.selected.comune.comune;
          this.anagrafica.provincia = this.selected.comune.provincia;
          this.anagrafica.regione = this.selected.comune.regione;
        }
      },
      deep: true,
    },
    tab_index: function (val) {
      if (val == 2) {
        window.scrollTo(0, 0);
      }
    },
  },
  computed: {
    ...mapState("utility", ["searching", "comuni", "province", "regioni"]),
    validatePassword() {
      if (this.form.password) {
        this.has_minimum_lenth = this.form.password.length > 8;
        this.has_number = /\d/.test(this.form.password);
        this.has_lowercase = /[a-z]/.test(this.form.password);
        this.has_uppercase = /[A-Z]/.test(this.form.password);
        this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.form.password);
        const regName = new RegExp(this.anagrafica.name.toLowerCase());
        const regSurname = new RegExp(this.anagrafica.surname.toLowerCase());
        this.has_name = !regName.test(this.form.password.toLowerCase());
        this.has_surname = !regSurname.test(this.form.password.toLowerCase());
        return (
          this.has_minimum_lenth &&
          this.has_number &&
          this.has_lowercase &&
          this.has_uppercase &&
          this.has_name &&
          this.has_surname &&
          this.has_special
        );
      }
      return false;
    },
    validateConfirm() {
      if (this.form.password) {
        return this.form.password_confirmation === this.form.password;
      }
      return false;
    },
    validEmail() {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return this.form.email.match(validRegex) ? true : false;
    },
    validCf() {
      var validRegex = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;
      if (this.anagrafica.cf.length === 16) {
        return this.anagrafica.cf.match(validRegex) ? this.fillOthers() : false;
      }
      return this.anagrafica.cf.match(validRegex) ? true : false;
    },
    validNumber() {
      if (this.anagrafica.tel_number) {
        const prefix = this.anagrafica.tel_number.slice(0, 2);
        const hasPrefix =
          prefix === "00" || this.anagrafica.tel_number.indexOf("+") >= 0;
        return !isNaN(this.anagrafica.tel_number) && !hasPrefix;
      }
      return false;
    },
    enableSecondBtn() {
      if (this.form.type !== null) {
        if (this.validatePassword && this.validateConfirm && this.validNumber) {
          return true;
        }
        return false;
      }
      return false;
    },
    enableThirdBtn() {
      if (
        this.form.data_consense &&
        this.form.share_consense &&
        this.form.charge_consense
      ) {
        return true;
      }
      return false;
    },
    nazioniOptions() {
      if (this.nazioni && this.nazioni.length > 0) {
        return [
          { value: null, text: "Seleziona Nazione" },
          { value: "italia", text: "Italia" },
        ].concat(
          this.nazioni.map((nazione) => {
            return {
              value: nazione.nazione,
              text: nazione.nazione
                .toLowerCase()
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" "),
            };
          })
        );
      } else
        return [
          {
            value: null,
            text: "Seleziona Nazione",
          },
        ];
    },
    // enableThirdBtn() {
    //   if (this.form.data_consense && this.form.share_consense) {
    //     if (this.form.type == 0 && !this.form.charge_consense) {
    //       return false;
    //     }
    //     return true;
    //   }
    //   return false;
    // },
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions("utente", ["register"]),
    ...mapActions("utility", ["getComuni", "getProvince", "getRegioni"]),
    OnSubmit() {
      if (!this.enableThirdBtn) {
        return false;
      }
      if (this.form.type === 1) {
        this.form.charge_consense = undefined;
      } else {
        // this.form.company_code = undefined;
      }
      //console.log(this.form);
      if (this.form) {
        this.form.anagrafica = this.anagrafica;
        this.register(this.form)
          .then((res) => {
            window.scrollTo(0, 0);
          })
          .catch((error) => {
            //console.log("after login error", error);
          });
      }
    },
    fillOthers() {
      try {
        var cf = this.anagrafica.cf;
        let getYear = cf.substr(6, 2);
        let getMonth = this.cfMonths[cf.substr(8, 1)];
        let getDay = parseInt(cf.substr(9, 2));
        let day = getDay > 31 ? getDay - 40 : getDay;
        this.anagrafica.gender = getDay > 31 ? "femminile" : "maschile";
        //console.log(parseInt(getYear));
        let currentYear = moment().year() - 2000;
        let yearPrefix =
          parseInt(getYear) >= 0 && parseInt(getYear) <= currentYear
            ? "20"
            : "19";
        var stringBDate = yearPrefix + getYear + "/" + getMonth + "/" + day;
        var isDateValid = moment(stringBDate).isValid();
        //console.log(isDateValid);
        this.anagrafica.birthday = moment(stringBDate).format("yyyy-MM-DD");
        if (cf.substr(11, 1) === "Z") {
          this.anagrafica.nazione_nascita = this.nazioni.find(
            (nazione) => nazione.codice === cf.substr(11, 4)
          )?.nazione;
        } else {
          this.anagrafica.nazione_nascita = "italia";
          this.searchComuneViaCCatastale(cf.substr(11, 4));
        }
        return isDateValid;
      } catch (err) {
        return false;
      }
    },
    checkCode() {
      //console.log(this.form.company_code);
      //console.log(this.form.company_code.length);
      if (this.form.company_code !== null) {
        this.canSubmit = this.form.company_code.length >= 10;
      }
    },
    setDomicilioData() {
      //console.log("on change", this.selected.comune);
      if (this.selected.comune) {
        this.anagrafica.comune = this.selected.comune.comune;
        this.anagrafica.provincia = this.selected.comune.provincia;
        this.anagrafica.regione = this.selected.comune.regione;
      }
    },
    findComune(comune) {
      if (this.anagrafica.comune) {
        if (comune.comune === this.anagrafica.comune) {
          this.selected.comune = comune;
          return true;
        }
      }
      return false;
    },
    searchComuneViaCCatastale(codice) {
      const self = this;
      utilityService
        .searchComuneViaCodiceCatastale(codice)
        .then(function (res) {
          console.log(res.data.data);
          if (res.data.data) {
            self.selected.regione = res.data.data.regione;
            self.getProvince(res.data.data);
            self.selected.provincia = res.data.data.provincia;
            self.getComuni(res.data.data);
            self.selected.comune = res.data.data;
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    getNazioni() {
      var self = this;
      utilityService
        .nazioni()
        .then((res) => {
          self.nazioni = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getRegioni();
    this.getNazioni();
  },
};
</script>
<style>
div.card-header {
  background-color: #fff !important;
}

.nav-item > a.bg-white:hover {
  background-color: #fff !important;
}

.info_icon {
  position: absolute;
  left: 3.5rem;
  color: transparent !important;
  cursor: pointer;
}

/* .fs-5 {
  font-size: 89%;
  font-weight: 700;
} */
.informativa__p {
  overflow-y: scroll;
  padding: 0 5px;
  max-height: 250px;
}
</style>
