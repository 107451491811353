<template>
  <b-container>
    <b-row cols="1" class="text-left mb-3">
      <router-link
        class="fw-bolder text-primary no__hover"
        :to="{ name: 'CittadinoHome' }"
      >
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Homepage</router-link
      >
    </b-row>
    <b-row class="col-12 justify-content-center">
      <b-button-group class="mb-3 bg-line-grey rounded-pill flex-wrap">
        <b-button
          class="fw-bolder text-capitalize"
          :size="$root.isMobile ? 'sm' : ''"
          @click="activePanel = 'rilevazioni'"
          :variant="
            activePanel === 'rilevazioni'
              ? 'primary primary_gradient text-white rounded-pill'
              : 'bg-light text-primary'
          "
          >rilevazioni</b-button
        >
        <b-button
          class="fw-bolder text-capitalize"
          :size="$root.isMobile ? 'sm' : ''"
          @click="activePanel = 'patologie'"
          :variant="
            activePanel === 'patologie'
              ? 'primary primary_gradient text-white rounded-pill'
              : 'bg-light text-primary'
          "
          >patologie</b-button
        >
        <b-button
          class="fw-bolder text-capitalize"
          :size="$root.isMobile ? 'sm' : ''"
          @click="activePanel = 'therapies'"
          :variant="
            activePanel === 'therapies'
              ? 'primary primary_gradient text-white rounded-pill'
              : 'bg-light text-primary'
          "
          >armadietto</b-button
        >
        <b-button
          class="fw-bolder text-capitalize"
          :size="$root.isMobile ? 'sm' : ''"
          @click="activePanel = 'pic_plan'"
          :variant="
            activePanel === 'pic_plan'
              ? 'primary primary_gradient text-white rounded-pill'
              : 'bg-light text-primary'
          "
          >piani terapeutici</b-button
        >
        <b-button
          class="fw-bolder text-capitalize"
          :size="$root.isMobile ? 'sm' : ''"
          @click="activePanel = 'note'"
          :variant="
            activePanel === 'note'
              ? 'primary primary_gradient text-white rounded-pill'
              : 'bg-light text-primary'
          "
          >note</b-button
        >
      </b-button-group>
    </b-row>
    <transition-group name="list" mode="out-in" appear>
      <b-row v-if="activePanel === 'rilevazioni'" key="rilevazioni">
        <RilevazioniComponent :subject="subject" />
      </b-row>
      <b-row v-if="activePanel === 'patologie'" key="patologie">
        <h6
          class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey ml-3 mt-3"
        >
          Patologie
        </h6>
        <PatologieComponent :subject="subject" />
      </b-row>
      <b-row v-if="activePanel === 'therapies'" key="therapies">
        <ArmadiettoComponent :subject="subject" />
      </b-row>
      <b-row v-if="activePanel === 'pic_plan'" key="pic_plan">
        <PianiTerapeutici />
      </b-row>
      <b-row v-if="activePanel === 'note'" key="note">
        <PatientAnnotations :subjectHash="subject && subject.hashid" :hidetop="false" />
      </b-row>
    </transition-group>
  </b-container>
</template>
<script>
import { mapState, mapActions } from "vuex";
import RilevazioniComponent from "@/components/profile/sanitario/RilevazioniComponent.vue";
import PatologieComponent from "@/components/profile/sanitario/PatologieComponent.vue";
import ArmadiettoComponent from "@/components/profile/sanitario/ArmadiettoComponent.vue";
import PatientAnnotations from "@/components/pazienti/note/PatientAnnotations.vue";

import PianiTerapeutici from "@/components/clienti/PianiTerapeutici.vue";
export default {
  name: "ProfiloSanitario",
  components: {
    RilevazioniComponent,
    PatologieComponent,
    ArmadiettoComponent,
    PianiTerapeutici,
    PatientAnnotations
  },
  data() {
    return {
      activePanel: "rilevazioni",
      subject: null,
      panels: ["rilevazioni", "patologie", "therapies", "pic_plan"],
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    ...mapActions("utente", ["me"]),
    fetchUser() {
      // get user by id, then subjectId = user fetched hashid
      // subject:{
      //   hashid:user.hashid,
      //   type:"familiare"
      // }
    },
  },
  created() {
    if (this.$route.params.subject) {
      this.fetchUser();
    }
    if (
      this.$route.query.scope &&
      this.panels.includes(this.$route.query.scope)
    ) {
      this.activePanel = this.$route.query.scope;
    }
  },
};
</script>
