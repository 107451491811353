<template>
  <b-container fluid v-if="user" class="mb-4">
    <b-row cols="2" class="text-left mb-3">
      <router-link
        class="fw-bolder text-primary no__hover col-5 col-md-6 px-0"
        :to="{ name: 'Pazienti' }"
      >
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Pazienti
      </router-link>
      <router-link
        class="fw-bolder text-primary no__hover text-right col-7 px-0 col-md-6"
        :to="{
          name: 'impostazione alerts',
          params: { hash: $route.params.hash },
          query: { from: 'clients' },
        }"
      >
        Impostazione alert
        <b-icon icon="arrow-right-circle-fill" variant="primary"></b-icon>
      </router-link>
    </b-row>
    <b-row
      cols="2"
      class="mb-4 panel__box__shadow bg-light-panel border border-light-panel general_border"
    >
      <div
        class="text-left mt-3 col-12 col-md-3 px-0 long__txt"
        style="font-size: 1.15rem; letter-spacing: 0.05rem"
      >
        <b-row cols="1" class="px-md-2 px-1 px-lg-3">
          <b-col class="text-center px-md-1 px-0">
            <strong
              >Aderenza
              <b-icon
                icon="info-circle"
                class="point text-justify"
                v-b-tooltip.hover.rightbottom="aderDescription"
              >
              </b-icon> </strong
            ><br />
            <AdherenceIndex :hash="user.hashid"></AdherenceIndex>
          </b-col>

          <b-col v-if="canEditAnagrafica" class="mb-2 text-center pr-0">
            <b-button
              size="sm"
              class="mx-auto"
              variant="secondary"
              @click="$bvModal.show('anagraphic_editor')"
            >
              <strong>Modifica anagrafica</strong>
            </b-button>
          </b-col>
          <b-col class="mb-2 px-md-1 px-0">
            <strong>Nome </strong><br />
            <span class="text-capitalize"
              >{{ user.anagrafica.name }}
              {{ user.anagrafica.surname }}
            </span>
          </b-col>
          <b-col class="mb-2 px-md-1 px-0">
            <strong>Genere </strong><br />
            <span>{{ user.anagrafica.gender }} </span>
          </b-col>
          <b-col class="mb-2 px-md-1 px-0">
            <strong>Data di nascita </strong><br />
            <span v-if="user.anagrafica.birthday"
              >{{ user.anagrafica.birthday | moment("DD/MM/YYYY") }}
            </span>
            <span v-else>Non indicata</span>
          </b-col>
          <b-col class="mb-2 px-md-1 px-0">
            <strong>Codice fiscale </strong><br />
            <span class="text-uppercase">{{ user.anagrafica.cf }} </span>
          </b-col>
          <b-col v-if="user.anagrafica.medico_base" class="mb-2 px-md-1 px-0">
            <strong>Medico di base </strong><br />
            <span class="text-capitalize"
              >{{ user.anagrafica.medico_base }}
            </span>
          </b-col>
          <b-col
            v-if="user.anagrafica.denominazione_asl"
            class="mb-2 px-md-1 px-0"
          >
            <strong>ASL/ASP/ATS</strong><br />
            <span>{{ user.anagrafica.denominazione_asl }} </span>
          </b-col>
          <b-col class="mb-2 px-md-1 px-0">
            <strong>Regione </strong><br />
            <span class="text-capitalize">{{ user.anagrafica.regione }} </span>
          </b-col>
          <b-col class="mb-2 px-md-1 px-0">
            <strong>Provincia </strong><br />
            <span class="text-capitalize"
              >{{ user.anagrafica.provincia }}
            </span>
          </b-col>
          <b-col class="mb-2 px-md-1 px-0">
            <strong>Comune </strong><br />
            <span class="text-capitalize">{{ user.anagrafica.comune }} </span>
          </b-col>
          <b-col class="mb-2 px-md-1 px-0">
            <strong>Indirizzo </strong><br />
            <span class="text-capitalize"
              >{{
                user.anagrafica.address
                  ? user.anagrafica.address
                  : "Non indicato"
              }}
            </span>
          </b-col>
          <b-col class="mb-2 px-md-1 px-0">
            <strong>Telefono </strong><br />
            <span
              >{{
                user.anagrafica.tel_number
                  ? user.anagrafica.tel_number
                  : "Non indicato"
              }}
            </span>
          </b-col>
          <b-col class="mb-2 px-md-1 px-0">
            <strong>Numero casa </strong><br />
            <span
              >{{
                user.anagrafica.home_number
                  ? user.anagrafica.home_number
                  : "Non indicato"
              }}
            </span>
          </b-col>
          <b-col v-if="user.email" class="mb-4 px-md-1 px-0">
            <strong>Email </strong><br />
            <span>{{ user.email }} </span>
          </b-col>
          <b-col v-else class="mb-4 px-md-1 px-0">
            <strong>Numero di riserva </strong><br />
            <span>{{ user.anagrafica.tel_number_secondary }} </span>
          </b-col>

          <!-- <b-col class="mb-2  px-md-1 px-0">
            <strong>Indice Aderenza </strong><br />
            <b-iconstack font-scale="1.2">
              <b-icon stacked icon="circle-fill" variant="success"></b-icon>
              <b-icon
                stacked
                icon="circle"
                variant="success"
              ></b-icon> </b-iconstack
            ><span class="ml-2">ottimale</span><br />
            <b-iconstack font-scale="1.2">
              <b-icon stacked icon="circle-fill" variant="white"></b-icon>
              <b-icon
                stacked
                icon="circle"
                variant="warning"
              ></b-icon> </b-iconstack
            ><span class="ml-2">discreto</span><br />
            <b-iconstack font-scale="1.2">
              <b-icon stacked icon="circle-fill" variant="white"></b-icon>
              <b-icon
                stacked
                icon="circle"
                variant="danger"
              ></b-icon> </b-iconstack
            ><span class="ml-2">inadeguato</span>
          </b-col> -->
        </b-row>
      </div>
      <div
        class="col-12 col-md-9 general_border bg-white half__border px-0 pt-2"
      >
        <template v-if="!blockView">
          <b-row class="col-12 justify-content-center">
            <b-button-group class="mb-3 bg-line-grey rounded-pill flex-wrap">
              <b-button
                class="fw-bolder text-capitalize"
                :size="$root.isMobile ? 'sm' : ''"
                @click="activePanel = 'prenotazioni'"
                :variant="
                  activePanel === 'prenotazioni'
                    ? 'primary primary_gradient text-white rounded-pill'
                    : 'bg-light text-primary'
                "
                >prenotazioni</b-button
              >
              <b-button
                class="fw-bolder text-capitalize"
                :size="$root.isMobile ? 'sm' : ''"
                @click="activePanel = 'terapie'"
                :variant="
                  activePanel === 'terapie'
                    ? 'primary primary_gradient text-white rounded-pill'
                    : 'bg-light text-primary'
                "
                >terapie</b-button
              >
              <!-- <template v-if="user.am_his_fav"> -->
              <b-button
                class="fw-bolder text-capitalize"
                :size="$root.isMobile ? 'sm' : ''"
                @click="activePanel = 'patologie'"
                :variant="
                  activePanel === 'patologie'
                    ? 'primary primary_gradient text-white rounded-pill'
                    : 'bg-light text-primary'
                "
                >patologie</b-button
              >
              <b-button
                class="fw-bolder text-capitalize"
                :size="$root.isMobile ? 'sm' : ''"
                @click="activePanel = 'rilevazioni'"
                :variant="
                  activePanel === 'rilevazioni'
                    ? 'primary primary_gradient text-white rounded-pill'
                    : 'bg-light text-primary'
                "
                >rilevazioni</b-button
              >
              <!-- </template> -->
              <!-- //TO DO: verify FDS service -->
              <b-button
                class="fw-bolder text-capitalize"
                :size="$root.isMobile ? 'sm' : ''"
                @click="activePanel = 'pic_plan'"
                :variant="
                  activePanel === 'pic_plan'
                    ? 'primary primary_gradient text-white rounded-pill'
                    : 'bg-light text-primary'
                "
                >piani terapeutici</b-button
              >
              <b-button
                class="fw-bolder text-capitalize"
                :size="$root.isMobile ? 'sm' : ''"
                @click="activePanel = 'questionari_utente'"
                :variant="
                  activePanel === 'questionari_utente'
                    ? 'primary primary_gradient text-white rounded-pill'
                    : 'bg-light text-primary'
                "
                >Questionari</b-button
              >
               <b-button
                class="fw-bolder text-capitalize"
                :size="$root.isMobile ? 'sm' : ''"
                @click="activePanel = 'note'"
                :variant="
                  activePanel === 'note'
                    ? 'primary primary_gradient text-white rounded-pill'
                    : 'bg-light text-primary'
                "
                >note</b-button>
            </b-button-group>
          </b-row>

          <transition-group name="slideside" mode="out-in">
            <b-row v-if="activePanel === 'rilevazioni'" key="rilevazioni">
              <RilevazioniComponent :subject="subject" />
            </b-row>
            <b-row v-if="activePanel === 'patologie'" key="patologie">
              <h6
                class="text-prim-grad-1 fw-bolder text-left pb-1 ml-3 mt-3 col-12 border-bottom border-line-grey"
              >
                Patologie
              </h6>
              <PatologieComponent :subject="subject" />
            </b-row>
            <b-row v-if="activePanel === 'terapie'" key="terapie">
              <TerapieComponent :subject="subject" />
            </b-row>
            <b-row v-if="activePanel === 'prenotazioni'" key="prenotazioni">
              <PrenotazioniCliente :subject="subject" />
            </b-row>
            <b-row v-if="activePanel === 'pic_plan'" key="pic_plan">
              <PianiTerapeutici :subject="subject" />
            </b-row>
            <b-row
              v-if="activePanel === 'questionari_utente'"
              key="questionari_utente"
            >
              <ReportQuestionariUtente :subject="subject" />
            </b-row>
            <b-row
              v-if="activePanel === 'note'"
              key="note"
            >
            <PatientAnnotations
                :subjectHash="subject.hashid"
                :hidetop="false"
              />
            </b-row>
          </transition-group>
        </template>
        <div v-else class="py-4 text-center">
          <OtpValidation
            :user="user"
            :cause="1"
            :validatable="user.hashid"
            :second="doBackupNumber"
            @otp-validated="handleOtpValidation($event)"
          >
            <template v-slot:instruction>
              <h1>
                <b-icon
                  icon="exclamation-circle-fill"
                  variant="danger"
                ></b-icon>
              </h1>
              <h2>
                E' necessario verificare il numero di telefono
                {{ doBackupNumber ? " di riserva " : "" }} del paziente.
              </h2>
              <h5 class="my-3">
                Clicca su "invia codice di verifica" per inviare il codice OTP
                di verifica via SMS
                {{ doBackupNumber ? " al numero di riserva " : "" }}
              </h5>
              <h6 class="my-3">
                Attenzione! Prima di procedere all'invio verificare che i dati
                inseriti siano corretti.
              </h6>
            </template>
          </OtpValidation>
        </div>
      </div>
    </b-row>
    <b-modal
      id="anagraphic_editor"
      size="xl"
      title="Modifica anagrafica paziente"
      hide-footer
    >
      <p class="mb-0 px-1 text-font-grey fw-bolder fs-6">
        Se necessario, modifica le informazioni anagrafiche del paziente
        essenziali per abilitarne l'utenza prestando particolare attenzione ai
        dati di contatto ( email e numero di telefono), per i quali è richiesta
        la validazione.
      </p>
      <PazienteAnagraficaEditor
        :user="user"
        @cancel-operation="$bvModal.hide('anagraphic_editor')"
        @edit-done="handleEditDone"
      ></PazienteAnagraficaEditor>
    </b-modal>
  </b-container>
  <h5 v-else-if="notFound">Utente Non trovato</h5>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { cittadinoService } from "@/_services";
import RilevazioniComponent from "@/components/profile/sanitario/RilevazioniComponent.vue";
import PatologieComponent from "@/components/profile/sanitario/PatologieComponent.vue";
import AdherenceIndex from "@/components/profile/sanitario/AdherenceIndex.vue";
import TerapieComponent from "@/components/clienti/TerapieComponent.vue";
import PrenotazioniCliente from "@/components/clienti/PrenotazioniCliente.vue";
import PianiTerapeutici from "@/components/clienti/PianiTerapeutici.vue";
import OtpValidation from "@/components/utilities/OtpValidation.vue";
import PazienteAnagraficaEditor from "@/components/pazienti/PazienteAnagraficaEditor.vue";
import ReportQuestionariUtente from "@/components/profile/sanitario/ReportQuestionariUtente.vue";
import PatientAnnotations from "@/components/pazienti/note/PatientAnnotations.vue";

export default {
  name: "SchedaCliente",
  components: {
    RilevazioniComponent,
    PatologieComponent,
    TerapieComponent,
    PrenotazioniCliente,
    AdherenceIndex,
    PianiTerapeutici,
    OtpValidation,
    PazienteAnagraficaEditor,
    ReportQuestionariUtente,
    PatientAnnotations,
  },
  data() {
    return {
      panels: [
        "pic_plan",
        "rilevazioni",
        "patologie",
        "terapie",
        "prenotazioni",
        "questionari_utente",
      ],
      user: null,
      activePanel: "prenotazioni",
      subject: null,
      notFound: false,
      aderDescription:
        "Medical Possession Rate (MPR). La valutazione di aderenza viene effettuata sulle terapie croniche attraverso un metodo indiretto che mette in relazione il numero di giorni di terapia e l'intervallo tra primo ed ultimo alert di promemoria. Il dato qui mostrato rappresenta una media dei valori di aderenza di tutte le terapie croniche e in atto del paziente.",
      blockView: false,
      doBackupNumber: false,
    };
  },
  computed: {
    canEditAnagrafica() {
      if (this.user && this.user.created_by_me) {
        if (this.user.email_verified_at || this.user.number_verified_at) {
          return false;
        }
        return this.user.all_consenses === false;
      }
      if (this.user && this.user.is_my_patient) {
        if (this.user.email_verified_at || this.user.number_verified_at) {
          return false;
        }
        return this.user.all_consenses === false;
      }
      return false;
    },
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchUser() {
      const self = this;
      cittadinoService
        .getUserById(this.$route.params.hash)
        .then(function (res) {
          self.user = res.data.data;
          self.subject = {
            hashid: res.data.data.hashid,
            type: "user_in_app",
          };
          self.verifyCredentials(res.data.data);
          // self.memberList = res.data.data;
        })
        .catch(function (err) {
          console.log(err);
          self.notFound = true;
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le informazioni.");
        });
    },
    verifyCredentials(user) {
      if (!user.number_verified_at) {
        return (this.blockView = true);
      }
      //DISABILITATA VERIFICA OBBLIGATORIA SUL SECONDO NUMERO
      // if (!user.email && !user.second_number_verified_at) {
      //   this.doBackupNumber = true;
      //   return (this.blockView = true);
      // }
      this.blockView = false;
    },
    handleOtpValidation(hasVerified) {
      if (hasVerified) {
        return this.fetchUser();
      }
      this.$router.push({ name: "Pazienti" });
    },
    handleEditDone() {
      this.fetchUser();
      this.$bvModal.hide("anagraphic_editor");
    },
  },
  created() {
    this.fetchUser();
  },
  mounted() {
    if (
      this.$route.query.panel &&
      this.panels.includes(this.$route.query.panel)
    ) {
      this.activePanel = this.$route.query.panel;
    }
  },
};
</script>
<style></style>
