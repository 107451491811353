<template>
  <div>
    <div v-if="!started">
      <div class="col-sm-12 col-lg-5 mx-auto my-4 px-0 px-md-2">
        <p class="text-justify">
          {{ questionario.info }}
          <!--  Maggiori dettagli saranno reperibili alla
          <a href="https://sanita.teleconsulto.online/privacy" class="text-secondary" target="_blank">Privacy Policy</a>. Le
          condizioni di utilizzo sono consultabili
          <a href="https://sanita.teleconsulto.online/tec/" class="text-secondary" target="_blank">qui</a> -->
          .
        </p>
        <!-- <p class="text-justify">
          Maggiori dettagli saranno reperibili alla
          <a href="https://teleconsulto.online/privacy" class="text-secondary"
            >Privacy Policy</a
          >. Le condizioni di utilizzo sono consultabili
          <a href="https://teleconsulto.online/tec/" class="text-secondary"
            >qui</a
          >
          .
        </p> -->
      </div>
      <b-button
        variant="primary font-weight-bold rounded-pill"
        class="mx-auto my-2"
        @click="start"
        >Ok, Procedi
      </b-button>
    </div>
    <div v-else>
      <b-tabs
        align="center"
        active-nav-item-class="font-weight-bold text-secondary border-0"
        active-tab-class="font-weight-bold text-dark"
        content-class="mt-3"
        nav-class="border-0 text-white"
        no-key-nav
        v-model="questIndex"
      >
        <b-tab
          title
          v-for="(domanda, ind) in domande"
          :key="ind"
          title-item-class="disabledTab"
        >
          <div class="mx-auto my-4">
            <h5 class="text-secondary">
              <span class="text-primary">{{ questionIndex(ind) }}.</span>
              {{ domanda.q }}
            </h5>
            <br />
            <div v-if="domanda.o" class="py-2">
              <div
                v-if="domanda.o !== 'file'"
                class="d-flex flex-wrap align-content-stretch justify-content-center"
              >
                <b-button
                  v-for="(opzione, indx) in domanda.o"
                  :key="indx"
                  variant="outline-secondary font-weight-bold fixed__h"
                  class="col-lg-3 col-sm-4 mx-1 my-2 text__control"
                  @click="selectOption(domanda, opzione)"
                  >{{ opzione }}</b-button
                >
              </div>
              <div v-else>
                <SurveyUploader
                  v-on:upload-result="handleUpload($event, ind)"
                  v-on:remove-result="removeUpload(ind)"
                />
              </div>
              <br />
              <span v-if="domanda.o !== 'file'"
                >Se vuoi, aggiungi un commento</span
              >
            </div>
            <b-form-textarea
              v-if="domanda.o !== 'file'"
              v-model="domanda.a"
              class="my-2 py-2 shadow_6 col-10 mx-auto"
            >
            </b-form-textarea>
          </div>
          <b-button
            variant="secondary font-weight-bold rounded-pill"
            class="mx-auto my-2 col-10 col-lg-3"
            @click="next"
            >Procedi</b-button
          >
          <!-- <b-button
            variant="primary"
            class="col-3 mx-auto my-2"
            @click="next"
            :disabled="domanda.a.length <1"
          >Procedi</b-button>-->
          <br />
        </b-tab>
        <b-tab title title-item-class="disabledTab">
          <div class="col-sm-12 col-lg-7 mx-auto">
            <h5 class="text-secondary">
              Grazie per aver compilato il questionario!
            </h5>
            <br />
            <p v-if="!afteres">Puoi procedere con la prenotazione.</p>
            <br />
            <b-button
              variant="primary font-weight-bold rounded-pill"
              class="mx-auto my-2 col-10 col-lg-5"
              @click="finalizza"
            >
              <b-spinner v-if="sending"></b-spinner> <span v-else>Procedi</span>
            </b-button>
          </div>
          <br />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import { supportService } from "@/_services";
import { mapState, mapActions } from "vuex";
import SurveyUploader from "@/components/utilities/SurveyUploader.vue";
export default {
  name: "FillSurvey",
  props: ["survey", "afteres"],
  components: { SurveyUploader },
  data() {
    return {
      questIndex: 0,
      started: false,
      domande: null,
      file: null,
      uploading: false,
      btnText: "Salva Immagine",
      questionario: null,
      sending: false,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    start() {
      this.started = true;
    },
    selectOption(domanda, opzione) {
      domanda.a = opzione;
    },
    next() {
      this.questIndex++;
    },
    finalizza() {
      this.sending = true;
      this.$emit("end-quest", this.domande);
    },
    handleUpload(data, ind) {
      if (this.domande[ind].a == null) {
        this.domande[ind].a = [];
      }
      this.domande[ind].a.push(data.hashid);
    },
    removeUpload(ind) {
      this.domande[ind].a = null;
    },
    questionIndex(i) {
      var index = i + 1;
      return index + "/" + this.domande.length;
    },
  },
  created() {
    this.questionario = JSON.parse(this.survey);
    this.domande = this.questionario.domande;
    if (this.questionario.info == null) {
      this.start();
    }
    //console.log(this.domande);
  },
};
</script>
<style scoped>
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Scegli";
}

.custom-file-input:lang(it) ~ .custom-file-label::after {
  content: "Scegli";
}

.fixed__h {
  min-height: 9vh;
}

.text__control {
  white-space: initial;
  word-wrap: break-word;
}
</style>
