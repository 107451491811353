<template>
  <div v-if="annotation && localAnnotation">
    <b-card
      no-body
      border-variant="white"
      class="panel__box__shadow bg-light-panel border border-light-panel general_border mb-3 p-3"
    >
      <b-row class="justify-content-between" align-v="center">
        <b-col class="pl-0 text-left fs-6">
          <template v-if="user.type !== 0">
            <template v-if="annotation.shared">
              <span class="ml-auto mr-0 text-secondary">
                <b-icon icon="eye" class="mx-1" variant="secondary"></b-icon>
                <span>Visibile al paziente</span>
              </span>
            </template>
            <span class="text-secondary" v-else>
              <b-icon icon="eye-slash" class="mx-1" variant="darkgrey"></b-icon>
              <span>Non visibile al paziente</span>
            </span>
          </template>
          <span class="fs-6 pl-2 fw-bolder">
            {{ formatMonth(annotation.created_at) }}</span
          >
        </b-col>
      </b-row>
      <b-row class="mt-2 justify-content-between" align-v="center">
        <span
          class="point text-left text-primary fw-bolder"
          @click="showContent = !showContent"
        >
          <b-avatar
            :src="annotation.operatore.profile_pic"
            size="1.7rem"
            :text="nomeOperatore"
            variant="primary"
            rounded="lg"
          >
          </b-avatar>
          <span class="text-capitalize">
            {{ annotation.operatore.fullname }},</span
          >
          <span> {{ annotation.perContoDellaCompany }}</span>
        </span>
        <b-col class="text-right">
          <b-icon
            :icon="showContent ? 'chevron-up' : 'chevron-down'"
            variant="darkgrey"
            class="point"
            @click="showContent = !showContent"
            font-scale="1.3"
          ></b-icon>
        </b-col>
      </b-row>

      <b-collapse
        :id="'nt_' + localAnnotation.hashid"
        v-model="showContent"
        class="m-2 mx-3"
      >
        <b-form-textarea
          class="shadow_6"
          v-model="localAnnotation.content"
          rows="1"
          :readonly="!editMode"
        >
        </b-form-textarea>
        <b-row
          align-v="center"
          class="py-1 mt-1 justify-content-between"
          v-if="annotation.own"
        >
          <template v-if="!editMode">
            <!-- TO DO: togliere flag is monitor e vincolare la visualizzazione da
            dentro il componente verificando che il servizio referti sia
            abilitato per la struttura dell'operatore -->
            <b-col class="pl-0" v-if="annotation.own"> </b-col>
          </template>
          <template v-else>
            <b-col v-if="!annotation.is_soap">
              <b-form-radio-group
                v-if="editMode"
                label="Visibilità Paziente"
                class="text-left pt-2"
                v-model="localAnnotation.shared"
              >
                <b-form-radio :value="0">Non visibile</b-form-radio>
                <b-form-radio :value="1">Visibile</b-form-radio>
              </b-form-radio-group>
            </b-col>
          </template>

          <b-col class="text-right pt-2">
            <template v-if="!editMode">
              <b-button
                v-b-tooltip.hover.bottom
                title="Abilita modifica"
                size="sm"
                variant="primary text-white"
                class="mr-4"
                @click="enableEditMode()"
              >
                <b-icon title="Abilita modifica" icon="pencil-fill"></b-icon>
              </b-button>
              <b-button
                v-b-tooltip.hover.bottom
                title="Elimina Nota"
                size="sm"
                variant="danger"
                @click="showDeleteModal()"
              >
                <b-icon icon="x" aria-label="Elimina Nota"></b-icon>
              </b-button>
            </template>
            <template v-else>
              <b-button
                @click="updateAnnotation()"
                size="sm"
                variant="primary mx-1"
              >
                Salva modifiche
              </b-button>
              <b-button
                @click="resetEdit()"
                size="sm"
                variant="outline-primary mx-1"
                >Annulla modifiche
              </b-button>
            </template>
          </b-col>
        </b-row>
      </b-collapse>
    </b-card>
    <b-modal
      v-if="allowModal"
      id="delete-annotation-confirm"
      size="lg"
      centered
      title="Conferma eliminazione"
      button-size="sm"
      cancel-title="No, Annulla"
      cancel-variant="secondary px-3 rounded-pill font-weight-bolder"
      ok-title="Sì, Conferma"
      ok-variant="outline-danger px-3 font-weight-bolder rounded-pill"
      footer-class="border-0"
      @ok="deleteAnnotation()"
      @cancel="$bvModal.hide('delete-annotation-confirm')"
    >
      Confermi di voler eliminare la nota?
    </b-modal>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { companyService } from "@/_services";

export default {
  name: "SingleAnnotationComponent",
  props: ["annotation"],
  components: {},
  computed: {
    nomeOperatore() {
      if (this.annotation.operatore.name && this.annotation.operatore.surname) {
        return (
          this.annotation.operatore.name[0] +
          this.annotation.operatore.surname[0]
        );
      }
      return "Op";
    },
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["user"]),
    ...mapState("company", ["company"]),
  },
  watch: {
    annotation: {
      handler(value) {
        this.localAnnotation.shared = value.shared ? true : false;
        this.localAnnotation.content = value.content;
        this.localAnnotation.subjective = value.subjective;
        this.localAnnotation.objective = value.objective;
        this.localAnnotation.assessment = value.assessment;
        this.localAnnotation.plan = value.plan;
      },
      deep: true,
    },
  },
  data() {
    return {
      localAnnotation: null,
      annotationHash: null,
      showContent: false,
      editMode: false,
      allowModal: false,
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    showDeleteModal() {
      this.allowModal = true;
      this.$bvModal.show("delete-annotation-confirm");
    },
    resetEdit() {
      this.editMode = false;
      this.$emit("do-update");
    },
    enableEditMode() {
      if (!this.editMode) this.editMode = true;
      this.showContent = true;
    },
    updateAnnotation() {
      var self = this;
      this.localAnnotation.is_soap = this.localAnnotation.is_soap
        ? true
        : false;
      this.localAnnotation.shared = this.localAnnotation.shared ? true : false;
      companyService
        .updateAnnotation(self.annotationHash, self.localAnnotation)
        .then(function (response) {
          window.scrollTo(0, 0);
          self.$emit("do-update");
          self.successAlert("Nota aggiornata correttamente");
          self.editMode = false;
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          self.errorAlert("Errore nell'aggiornamento della nota");
        });
    },
    deleteAnnotation() {
      this.$bvModal.hide("delete-annotation-confirm");
      const self = this;
      // console.log("Delete");
      companyService
        .deleteAnnotation(self.annotationHash)
        .then(function (response) {
          window.scrollTo(0, 0);
          self.successAlert("Nota eliminata correttamente");
          self.$emit("do-update");
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          self.errorAlert("Errore nell'eliminazione della nota");
        });
    },
    formatMonth(date) {
      return moment(date).format("D MMMM \or\\e HH:mm");
    },
  },
  mounted() {
    this.localAnnotation = {
      content: this.annotation.content,
      subjective: this.annotation.subjective,
      objective: this.annotation.objective,
      assessment: this.annotation.assessment,
      plan: this.annotation.plan,
      shared: this.annotation.shared,
    };
    this.annotationHash = this.annotation.hashid;
  },
};
</script>
