export const MONTHS = [
  { label: "Gennaio", value: 1 },
  { label: "Febbraio", value: 2 },
  { label: "Marzo", value: 3 },
  { label: "Aprile", value: 4 },
  { label: "Maggio", value: 5 },
  { label: "Giugno", value: 6 },
  { label: "Luglio", value: 7 },
  { label: "Agosto", value: 8 },
  { label: "Settembre", value: 9 },
  { label: "Ottobre", value: 10 },
  { label: "Novembre", value: 11 },
  { label: "Dicembre", value: 12 }
]

export const FREQUENCYMAP = {
  0: { singular: "mese", plural: "mesi" },
  1: { singular: "settimana", plural: "settimane" },
  2: { singular: "giorno", plural: "giorni" },
  3: { singular: "ora", plural: "ore" },
  4: { singular: "minuto", plural: "minuti" }
}
