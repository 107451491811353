<template>
  <div class="py-3">
    <div v-if="template" class="fw-bolder">
      <!-- <p class="text-justify text-medium-grey fw-bolder">
        {{ template.template.domande.length }} quesiti.
      </p> -->
      <b-form @submit.prevent="sendForm">
        <b-row cols="1" class="align-items-end">
          <transition-group name="list" mode="out-in" appear>
            <b-row
              cols="1"
              cols-md="2"
              v-for="(domanda, ind) in template.template.domande"
              :key="'qk_' + ind"
            >
              <b-col class="text-justify">
                <label :for="'q_' + ind"
                  >{{ ind + 1 + ". " + domanda.q }}
                  <span v-if="domanda.h" class="mb-0 text-primary">
                    - domanda per l'operatore</span
                  >
                </label>
              </b-col>
              <b-col class="text-justify">
                <b-form-group :key="'fgr_' + ind">
                  <div
                    v-if="domanda.o && domanda.o === 'file'"
                    class="align-self-center"
                  >
                    <SurveyUploader
                      v-on:upload-result="handleUpload($event, ind)"
                      v-on:remove-result="removeUpload(ind)"
                    />
                    <b-button
                      v-if="!template.template.domande[ind].a"
                      class="mt-3"
                      variant="secondary fw-bolder"
                      @click="
                        template.template.domande[ind].a =
                          'immagine non allegata'
                      "
                      >Salta</b-button
                    >
                  </div>
                  <!-- <template v-else-if="domanda.cb && domanda.o">
                    <b-form-checkbox-group :name="'q_' + ind" v-model="domanda.a" :options="domanda.o" name="flavour-1">
                    </b-form-checkbox-group>
                    <b-form-input v-if="domanda.a.includes('altro')" class="shadow_6 align-self-center"
                      placeholder="..." type="text" :name="'extra_q_' + ind" v-model="domanda.a" required>
                      </b-form-input>
                  </template> -->
                  <b-form-group
                    v-else-if="domanda.cb && domanda.o"
                    label="Seleziona almeno una opzione*"
                    :label-class="domanda.r ? 'fw-bolder underlined' : 'd-none'"
                  >
                    <b-form-checkbox-group
                      v-model="domanda.a"
                      :options="domanda.o"
                      :name="'checkboxquestions' + ind"
                      v-bind="
                        domanda.r && domanda.a.length === 0
                          ? { required: true }
                          : {}
                      "
                      label="Scegli almeno una opzione*"
                      label-class="fw-bolder"
                    ></b-form-checkbox-group>
                  </b-form-group>

                  <b-form-select
                    class="shadow_6 align-self-center"
                    v-else-if="domanda.o"
                    v-model="domanda.a"
                    :name="'q_' + ind"
                    required
                  >
                    <b-form-select-option
                      v-for="opt in domanda.o"
                      :key="'q_' + opt"
                      :value="opt"
                      >{{ opt }}
                    </b-form-select-option>
                    <!-- <b-form-select-option :value="null" disabled>seleziona</b-form-select-option> -->
                  </b-form-select>
                  <template v-else-if="domanda.t === 'date'">
                    <b-form-input
                      class="shadow_6"
                      type="date"
                      :name="'q_' + ind"
                      v-model="domanda.a"
                      :required="domanda.r !== undefined ? domanda.r : true"
                      :min="dateMin(domanda)"
                      :max="dateMax(domanda)"
                    ></b-form-input>
                  </template>
                  <template v-else-if="domanda.t === 'number'">
                    <!-- @keypress="checkNumber($event, domanda)" -->
                    <b-form-input
                      class="shadow_6"
                      type="text"
                      inputmode="numeric"
                      @beforeinput="handleNumericInput($event, domanda)"
                      :name="'q_' + ind"
                      v-model="domanda.a"
                      :required="domanda.r !== undefined ? domanda.r : true"
                    ></b-form-input>
                  </template>
                  <template v-else-if="domanda.d">
                    <b-row>
                      <b-col sm="6">
                        <label :for="'q_' + ind + '_min'">Min.</label>
                        <b-form-input
                          class="shadow_6 mb-2"
                          type="number"
                          step="1"
                          max="400"
                          :name="'q_' + ind + '_min'"
                          v-model="minP[ind]"
                          :required="domanda.r !== undefined ? domanda.r : true"
                          :change="setAnswer(minP[ind], maxP[ind], ind)"
                        ></b-form-input>
                      </b-col>
                      <b-col sm="6">
                        <label :for="'q_' + ind + '_max'">Max.</label>
                        <b-form-input
                          class="shadow_6 mb-2"
                          type="number"
                          step="1"
                          max="400"
                          :name="'q_' + ind + '_max'"
                          v-model="maxP[ind]"
                          :required="domanda.r !== undefined ? domanda.r : true"
                          :change="setAnswer(minP[ind], maxP[ind], ind)"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </template>
                  <b-form-input
                    v-else
                    class="shadow_6 align-self-center"
                    placeholder="..."
                    type="text"
                    :name="'q_' + ind"
                    v-model="domanda.a"
                    :required="domanda.r !== undefined ? domanda.r : true"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-col class="my-4 py-2" key="btsubmt">
              <b-spinner v-if="loading"></b-spinner>
              <b-button
                v-else
                type="submit"
                variant="primary primary_gradient text-white fw-bolder spacing_1"
              >
                Invia</b-button
              >
            </b-col>
          </transition-group>
        </b-row>
      </b-form>
    </div>
  </div>
</template>
<script>
import SurveyUploader from "@/components/utilities/SurveyUploader.vue";
import * as moment from "moment/moment"

export default {
  name: "CompilaQuestionario",
  props: ["survey"],
  components: { SurveyUploader },
  data() {
    return {
      template: null,
      consent: false,
      proceed: false,
      file: null,
      loading: false,
      minP: [],
      maxP: [],
    };
  },
  methods: {
    onFileChange(e) {
      this.file = e.target.files[0];
    },
    setAnswer(min, max, ind) {
      if (min > 0 && max > 0)
        this.template.template.domande[ind].a = min + "/" + max;
      else this.template.template.domande[ind].a = null;
    },
    sendForm() {
      this.loading = true;
      this.$emit("filled-form", this.template);
    },
    handleUpload(data, ind) {
      if (this.template.template.domande[ind].a == null) {
        this.template.template.domande[ind].a = [];
      }
      this.template.template.domande[ind].a.push(data.hashid);
    },
    removeUpload(ind) {
      this.template.template.domande[ind].a = null;
    },
    handleNumericInput(event, domanda) {
      if (
        event.inputType === "insertText" ||
        event.inputType === "insertFromPaste"
      ) {
        // Blocca spazi
        if (event.data.includes(" ")) {
          event.preventDefault()
          return
        }

        const currentValue = event.target.value
        const selectionStart = event.target.selectionStart
        const selectionEnd = event.target.selectionEnd

        // Gestione virgola decimale
        if (event.data === "," || event.data === ".") {
          // Se è specificato int, blocca la virgola
          if (domanda.int) {
            event.preventDefault()
            return
          }
          // Se c'è già una virgola nel numero, blocca
          if (currentValue.includes(",")) {
            event.preventDefault()
            return
          }
          // Se è il primo carattere, aggiungi uno zero davanti
          if (selectionStart === 0) {
            event.target.value = "0" + currentValue
            event.preventDefault()
            return
          }
          // Se il numero è già al massimo, blocca la virgola
          if (
            domanda.max !== undefined &&
            Number(currentValue) >= domanda.max
          ) {
            event.preventDefault()
            return
          }
          // Permetti solo la virgola, non il punto
          if (event.data === ".") {
            event.preventDefault()
            return
          }
          return // Permetti la virgola
        }

        // Gestione zero iniziale
        if (event.data === "0") {
          if (
            selectionStart === 0 &&
            currentValue.length > 0 &&
            (!domanda.int || currentValue[0] !== ",")
          ) {
            event.preventDefault()
            return
          }
        }

        // Verifica che sia un numero valido
        if (isNaN(event.data)) {
          event.preventDefault()
          return
        }

        const newValue =
          currentValue.slice(0, selectionStart) +
          event.data +
          currentValue.slice(selectionEnd)

        // Controllo decimali (massimo 3)
        const parts = newValue.split(",")
        if (parts.length > 1 && parts[1].length > 3) {
          event.preventDefault()
          return
        }

        // Per la validazione min/max, convertiamo la virgola in punto
        const numericValue = Number(newValue.replace(",", "."))
        if (
          (domanda.max !== undefined && numericValue > domanda.max) 
          // || (domanda.min !== undefined && numericValue < domanda.min)
        ) {
          event.preventDefault()
        }
      }
    },
    dateMin(domanda) {
      if (domanda.y && (domanda.y.min || domanda.y.min === 0)) {
        return moment()
          .subtract(domanda.y.min, "years")
          // .startOf("year")
          .format("YYYY-MM-DD")
      }
      return null
    },
    dateMax(domanda) {
      if (domanda.y && (domanda.y.max || domanda.y.max === 0)) {
        return moment()
          .subtract(domanda.y.max, "years")
          // .startOf("year")
          .format("YYYY-MM-DD")
      }
      return null
    }
  },
  created() {
    if (this.survey) {
      this.template = this.survey;
      try {
        var parsed = JSON.parse(this.survey.template);
        this.template.template = parsed;
      } catch {
        //
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
